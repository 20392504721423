<template>
  <div class="container">
    <div class="userInfo">
      <div class="userInfo_left">
        <div class="left">
          <!-- <div class="cover-text" v-show="user.isReview === 0">
            <div>审核中</div>
          </div> -->
          <img
            :src="user.photo ? `${apiURL}/image?name=${user.photo}` : none"
            alt
          />
        </div>
        <div class="right">
          <p>{{ user.name }}</p>
          <p>{{ user.phone }}</p>
        </div>
      </div>
      <div class="userInfo_right" @click="toPage('PersonInfo')">
        <img :src="right" alt />
      </div>
    </div>
    <div class="userInfo_wallet">
      <div class="userInfo_wallet_top">
        <p>钱包</p>
        <p @click="toPage('UserWallet')">
          <img style="width: 2rem; height: 1.5rem" :src="right" alt />
        </p>
      </div>
      <ul class="userInfo_wallet_bottom">
        <li>
          <p>{{ Number(user.balance / 100).toFixed(2) }}</p>
          <p>现金金额</p>
        </li>
        <li>
          <p>{{ Number(user.allowance / 100).toFixed(2) }}</p>
          <p>补助金</p>
        </li>
        <li>
          <p>{{ Number(user.coupon / 100).toFixed(2) }}</p>
          <p>优惠余额</p>
        </li>
      </ul>
    </div>
    <div class="userInfo_setting">
      <div class="userInfo_setting_top">
        <p>设置</p>
        <p @click="toPage('Setting')">
          <img style="width: 2rem; height: 1.5rem" :src="right" alt />
        </p>
      </div>
      <ul class="userInfo_setting_bottom">
        <li
          v-for="(item, index) in setting"
          :key="index"
          @click="toPage(item.func)"
        >
          <p>
            <img :src="item.img" alt />
          </p>
          <p>{{ item.title }}</p>
        </li>
      </ul>
    </div>
    <!-- 底部导航栏 -->
    <van-tabbar v-model="active">
      <van-tabbar-item replace to="/">
        <template #icon="props">
          <img :src="props.active ? homeActive : home" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/center">
        <template #icon="props">
          <img
            :src="props.active ? mineActive : mine"
            style="width: 30px; height: 30px"
          />
        </template>
      </van-tabbar-item>
    </van-tabbar>
    <!-- 联系电话 -->
    <div class="bottom_phone">
      <div>客服电话：028-60656777</div>
    </div>
  </div>
</template>

<script>
import { Swipe, SwipeItem } from 'c-swipe';
import { Toast } from 'vant';
import 'c-swipe/dist/swipe.css';
import mine from '@/assets/mine.png';
import home from '@/assets/home.png';
import mineActive from '@/assets/mine_active.png';
import homeActive from '@/assets/home_active.png';
import banner3 from '@/assets/banner3.png';
const { VUE_APP_BASE_API } = process.env;
import { getAccInfo } from '@/api/person';
import none from '@/assets/photo_none.png';
import right from '@/assets/right.png';
import faceBind from '../../assets/faceBind.png';
import pwd from '../../assets/pwd.png';
import familyList from '../../assets/familyList.png';
import vehicle from '../../assets/vehicle.png';
import unbind from '../../assets/unbind.png';
import facebind from '../../assets/face-bind.png';
import cardManage from '@/assets/icon-card-manage.png';
import { getUserSystemSetupAPI } from '@/api/family.js';
export default {
  name: 'Index',
  components: { Swipe, SwipeItem },
  data() {
    return {
      faceBind,
      unbind,
      facebind,
      none,
      right,
      logined: true,
      identification: true,
      functionalServices: [],
      active: 1,
      mine,
      home,
      mineActive,
      homeActive,
      // leave,
      index: 0,
      fileList: [],
      banners: [banner3],
      swiperOption: {
        speed: 800,
        loop: true,
        autoplay: {
          delay: 1500,
          disableOnInteraction: false
        }
      },
      loading: false,
      apiURL: process.env.VUE_APP_BASE_API,
      loadImg: false,
      card: '',
      fileImg: '',
      tip: false,
      user: {},
      userId: '',
      phoneShow: false,
      phone: '',
      organizationId: '',
      phones: [],
      orgShow: false,
      orgs: [],
      userShow: false,
      users: [],
      userID: '',
      tuitionPayUrl: `${VUE_APP_BASE_API}/icbc/tuition/pay`,
      infoData: null,
      picUrl: null,
      custID: null,
      showDialog: false,
      centerDialog: true,
      setting: [
        { img: facebind, title: '人脸绑定', func: 'FaceBinding' },
        {
          img: cardManage,
          title: '卡管理',
          func: 'CardManage'
        }
        // { img: unbind, title: "账号解绑", func: "AccUnbundling" },
      ]
    };
  },
  async created() {
    this.limitSettingFn();
    const { token } = this.$store.getters;
    const { queryEmployee, fetchBanners, getOrg, afterLogin } = this;
    const { phone, custID } = this.$route.query;
    if (!token) {
      if (phone) {
        if (phone.indexOf(',') > -1) {
          const phones = phone.split(',');
          this.phoneShow = true;
          this.phones = phones.map((value) => {
            return { name: value };
          });
        } else {
          this.phone = phone;
          getOrg({ phone });
        }
      } else if (custID) {
        sessionStorage.setItem(
          'custID',
          JSON.stringify(this.$route.query.custID)
        );
        this.$router.push({ name: 'Login', query: { custID } });
      } else {
        afterLogin(false);
      }
    } else {
      queryEmployee();
      fetchBanners();
    }

    if (!sessionStorage.getItem('showDialog')) {
      this.showDialog = true;
    }
    // this.getInfo()
    console.log(sessionStorage.getItem('openid'));
  },
  methods: {
    // 关闭遮罩层
    hideDialog() {
      this.showDialog = false;
      sessionStorage.setItem('showDialog', true);
    },
    // sleep(numberMillis) {
    //   var now = new Date()
    //   var exitTime = now.getTime() + numberMillis
    //   while (true) {
    //     now = new Date()
    //     if (now.getTime() > exitTime) return
    //   }
    // },
    async getInfo() {
      const res = await getAccInfo();
      // console.log(res)
      if (res.code === 0) {
        this.infoData = res.data;
        sessionStorage.setItem('personInfo', JSON.stringify(this.infoData));
      }
    },
    async toInfo() {
      const res = await getAccInfo();
      // this.sleep(2000)
      // console.log(res)
      if (res.code === 0) {
        this.$nextTick(() => {
          this.infoData = res.data;
          // this.picUrl = res.data.photo
          sessionStorage.setItem('personInfo', JSON.stringify(this.infoData));
          // localStorage.setItem('picUrl', JSON.stringify(this.picUrl))
          // this.infoUrl = `${this.apiUrl}/image?name=${this.picUrl}`
        });
        this.$router.push('/personInfo');
      } else {
        // console.log(111)
      }
    },

    async toAccUnbundling() {
      const res = await getAccInfo();
      if (res.code === 0) {
        this.$nextTick(() => {
          this.infoData = res.data;
          console.log(this.infoData, ' this.infoData');
          sessionStorage.setItem('personInfo', JSON.stringify(this.infoData));
        });
        this.$router.push('/accUnbundling');
      } else {
        // console.log(111)
      }
    },

    handler() {
      if (this.fileList.length !== 1) {
        this.$refs.uploader.chooseFile();
      } else {
        this.fileList = [];
        this.$nextTick(() => this.handler());
      }
    },
    handlerImage() {
      if (this.fileList.length !== 1) {
        this.$refs.uploaderImage.chooseFile();
      } else {
        this.fileList = [];
        this.$nextTick(() => this.handlerImage());
      }
    },
    handlerAlbum() {
      if (this.fileList.length !== 1) {
        this.$refs.uploaderAlbum.chooseFile();
      } else {
        this.fileList = [];
        this.$nextTick(() => this.handlerAlbum());
      }
    },
    handlePhoneSelect(item) {
      console.log(item);
      const { getOrg } = this;
      this.phoneShow = false;
      this.phone = item.name;
      getOrg();
    },
    afterLogin(logined) {
      console.log(logined);
      this.logined = logined;
    },
    async fetchBanners() {
      if (JSON.parse(sessionStorage.getItem('banners'))) {
        this.banners = JSON.parse(sessionStorage.getItem('banners'));
      }
    },
    handleOrgSelect(item) {
      const { getUser } = this;
      this.orgShow = false;
      this.organizationId = item.id;
      getUser();
    },
    handleUserSelect(item) {
      console.log(item);
      const { $store, queryEmployee, fetchBanners, afterLogin } = this;
      this.userShow = false;
      $store
        .dispatch('login/login', { userId: item.id })
        .then(() => {
          queryEmployee();
          fetchBanners();
        })
        .catch((err) => {
          afterLogin(false);
          console.log(err);
        });
    },
    queryEmployee() {
      this.loading = true;
      this.$store
        .dispatch('user/getInfo')
        .then((data) => {
          console.log('data', data);
          this.user = data;
          this.fileImg = data.photo;
          this.functionalServices = data.organization.functionalServices
            ? JSON.parse(data.organization.functionalServices)
            : [];
          console.log('functionalServices', this.functionalServices);
          this.loadImg = true;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toPage(name) {
      if (name === 'AccUnbundling') {
        this.toAccUnbundling();
        return;
      }
      this.$router.push({ name });
    },
    onOversize() {
      Toast.fail('照片超出大小，请控制在50M');
    },
    limitSettingFn() {
      if (!localStorage.getItem('openid')) {
        this.setting.push({
          img: unbind,
          title: '账号解绑',
          func: 'AccUnbundling'
        });
      }
      const user = JSON.parse(sessionStorage.getItem('personInfo'));
      if (user.organization.functionalServices.includes(1)) {
        this.setting.push({
          img: pwd,
          title: '消费密码设置',
          func: 'PwdSetting'
        });
      }

      this.getUserSystemSetupFn();
      // if (user.organization.functionalServices.includes(7)) {
      //   this.setting.push({
      //     img: vehicle,
      //     title: "我的车辆",
      //     func: "MyVehicle",
      //   });
      // }
    },

    async getUserSystemSetupFn() {
      const organizationId = JSON.parse(
        sessionStorage.getItem('personInfo')
      ).organizationId;
      const user = JSON.parse(sessionStorage.getItem('personInfo'));
      const res = await getUserSystemSetupAPI(organizationId);
      if (res.code === 0 && res.data !== null) {
        if (JSON.parse(res.data.setting) !== '{}') {
          if (JSON.parse(res.data.setting).family_set.flag && user.role === 0) {
            this.setting.push({
              img: familyList,
              title: '家属账户',
              func: 'FamilyList'
            });
          }
        }
      }
    }
  }
};
</script>
<style scoped lang="scss">
.container {
  .userInfo {
    height: 10rem;
    width: 100%;
    background: rgba(67, 110, 160);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .userInfo_left {
      display: flex;
      .left {
        margin: 0 1rem 0 1.5rem;
        height: 5rem;
        width: 5rem;
        overflow: hidden;
        border-radius: 0.25rem;
        border: 1px solid #cccccc;
        position: relative;
        .cover-text {
          width: 100%;
          height: 24px;
          border-radius: 12px;
          background: #517efd;
          color: #fff;
          text-align: center;
          position: absolute;
          font-size: 12px;
          top: 3.4rem;
          left: -1.5rem;
          z-index: 9999;
          transform: rotate(45deg);
          div {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .right {
        color: white;
      }
    }
    .userInfo_right {
      padding-right: 1rem;
      img {
        width: 2.5rem;
      }
    }
  }
  .userInfo_wallet {
    width: 95%;
    height: 8rem;
    margin: 0 auto;
    background: rgba(255, 255, 255);
    border-radius: 1rem;
    .userInfo_wallet_top {
      margin-top: 1rem;
      height: 2rem;
      display: flex;
      justify-content: space-between;
      font-size: 1.3rem;
      color: rgba(0, 0, 0, 0.9);
      p {
        margin: 0;
        font-weight: 700;
        margin-top: 0.5rem;
      }
      p:nth-child(1) {
        margin-left: 1rem;
      }
      p:nth-child(2) {
        margin-right: 1rem;
      }
    }
    .userInfo_wallet_bottom {
      width: 95%;
      display: flex;
      margin: auto;
      text-align: center;
      li {
        width: 25%;
        p:nth-child(1) {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 1rem;
        }
        p:nth-child(2) {
          font-size: 0.75rem;
        }
      }
    }
  }
  .userInfo_setting {
    width: 95%;
    padding-bottom: 1rem;
    margin: 0 auto;
    background: rgba(255, 255, 255);
    border-radius: 1rem;
    .userInfo_setting_top {
      margin-top: 1rem;
      height: 2rem;
      display: flex;
      justify-content: space-between;
      font-size: 1.3rem;

      color: rgba(0, 0, 0, 0.9);
      p {
        margin: 0;
        font-weight: 700;
        margin-top: 0.5rem;
      }
      p:nth-child(1) {
        margin-left: 1rem;
      }
      p:nth-child(2) {
        margin-right: 1rem;
      }
    }
    .userInfo_setting_bottom {
      width: 95%;
      display: flex;
      margin: auto;
      text-align: center;
      flex-wrap: wrap;
      li {
        width: 25%;
        p:nth-child(1) {
          margin: 0;
          margin-top: 1rem;
          img {
            width: 50%;
          }
        }
        p:nth-child(2) {
          margin: 0;
          font-size: 0.75rem;
        }
      }
    }
  }
  .bottom_phone {
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 5rem;
    color: black;
    font-size: 0.9rem;
    div {
      width: 80%;
      margin: 0 auto;
      text-align: center;
    }
  }
}
</style>
